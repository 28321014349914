import React, { useState, useEffect, useRef } from 'react'
import styles from './AllPosts.module.scss'
import Button from '../common/Button'
import { ArrowRightIcon, UserIcon } from '@heroicons/react/20/solid'
import { Link } from 'gatsby'
import { formatDateToDaysAgo } from '../../utils/format'
import { Pagination, Select } from '@mantine/core'

const postsPerPage = 12

const getTagTextColor = tag => {
  if (tag === 'Product') {
    return '#1E3023'
  } else if (tag === 'Community') {
    return '#1E2830'
  } else if (tag === 'Guides') {
    return '#2D1E30'
  } else if (tag === 'Case Studies') {
    return '#302D1E'
  } else {
    return '#1E3023'
  }
}

const getTagBackgroundColor = tag => {
  if (tag === 'Product') {
    return '#D7F3DE'
  } else if (tag === 'Community') {
    return '#D7E7F3'
  } else if (tag === 'Guides') {
    return '#E8D7F3'
  } else if (tag === 'Case Studies') {
    return '#F3F0D7'
  } else {
    return '#D7F3DE'
  }
}

const AllPosts = ({ posts }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const [currentTopic, setCurrentTopic] = useState('All')

  const filteredPosts =
    currentTopic === 'All'
      ? posts
      : posts.filter(post => post.frontmatter.topic === currentTopic)

  const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost)

  const paginate = pageNumber => {
    setCurrentPage(pageNumber)
  }

  const handleTopicChange = topic => {
    setCurrentTopic(topic)
    setCurrentPage(1) // Reset to the first page when changing the topic
  }

  const topics = ['All', 'Product', 'Community', 'Guides', 'Case Studies']

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h2>Browse all posts</h2>
        </div>
        {/* <div className={styles.mobileCategories}>
          <Select
            label="Category"
            data={topics}
            value={currentTopic}
            onChange={setCurrentTopic}
            classNames={{ item: styles.selectItem }}
          />
        </div>
        <div className={styles.tabs}>
          {topics.map(topic => (
            <div
              className={`${styles.tab} ${currentTopic === topic &&
                styles.active}`}
              onClick={() => handleTopicChange(topic)}
            >
              {topic}
            </div>
          ))}
        </div> */}
        <div className={styles.grid}>
          {currentPosts.map(post => (
            <PostCard
              id={post.id}
              title={post.frontmatter.title}
              excerpt={post.frontmatter.meta.description}
              slug={post.fields.slug}
              image={post.frontmatter.featuredImage}
              topic={post.frontmatter.topic}
              date={post.frontmatter.date}
              author={post.frontmatter.author.name}
            />
          ))}
        </div>
        <div className={styles.pagination}>
          <Pagination
            total={Math.ceil(filteredPosts.length / postsPerPage)}
            value={currentPage}
            onChange={paginate}
            classNames={{ control: styles.paginationControl }}
          />
        </div>
      </div>
    </div>
  )
}

const PostCard = ({ id, title, excerpt, slug, topic, image, date, author }) => {
  const [hover, setHover] = useState(false)
  return (
    <Link to={slug}>
      <div
        className={styles.postCard}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => setHover(false)}
      >
        <div className={`${styles.cardImage} ${hover && styles.hovered}`}>
          <img src={image} alt={title} />
        </div>
        <div className={`${styles.cardContent}`}>
          <h3 style={{ color: hover ? '#0f58ff' : '#0e101b' }}>{title}</h3>
          <p>{excerpt}</p>
          <div className={styles.bottomContent}>
            <UserIcon height={20} style={{ marginBottom: '-0.3rem' }} />{' '}
            {author}
            {topic && (
              <span
                className={styles.date}
                style={{
                  color: getTagTextColor(topic),
                  backgroundColor: getTagBackgroundColor(topic)
                }}
              >
                {topic}
              </span>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}

export default AllPosts
