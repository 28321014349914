import React, { useState, useEffect, useRef } from 'react'
import styles from './SpotlightPost.module.scss'
import { Link } from 'gatsby'

const SpotlightPost = ({ post }) => {
  const [hover, setHover] = useState(false)

  return (
    <div className={styles.section}>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1>Blog</h1>
          <p>
            Browse the Blog and customer stories to find what you need to
            succeed with Atlas.
          </p>
        </div>
        <Link to={post.fields.slug}>
          <div
            className={styles.postCard}
            onMouseOver={() => setHover(true)}
            onMouseOut={() => setHover(false)}
          >
            <div className={`${styles.cardImage} ${hover && styles.hovered}`}>
              <img
                src={post.frontmatter.featuredImage}
                alt={post.frontmatter.title}
              />
            </div>
            <div className={`${styles.cardContent}`}>
              <div>
                <h3 style={{ color: hover ? '#0f58ff' : '#0e101b' }}>
                  {post.frontmatter.title}
                </h3>
                <p>{post.frontmatter.meta.description}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default SpotlightPost
